@use "../config/" as *;
@forward "../shop/shop-common";
.waf-wp-shop-detail {
    padding-block: var(--space-8) var(--space-0);
    @extend %px-4;
    .preview-swiper {
        @extend %mb-5;
        .article-item {
            @extend %p-8-14;
            @extend %half-radius;
            @extend %neutral-100-bg-2;
            @extend %w-100;
        }
        .swiper-button-prev,
        .swiper-button-next {
            @extend %d-none;
        }
    }
    .img-box {
        border-radius: 0;
    }
    .thumbnail-swiper {
        .swiper-button {
            &-prev,
            &-next {
                transform: translateY(-50%);
                width: 3.5rem;
                @extend %pure-white-900-bg;
            }
            &-prev {
                &::after {
                    @include icon(circle-arrow-left, 20);
                }
            }
            &-next {
                &::after {
                    @include icon(circle-arrow-right, 20);
                }
            }
        }
    }
    .title {
        @extend %mt-5;
        @extend %capitalize;
        @extend %font-24-pb;
        @extend %neutral-800;
    }
    .product {
        &-description {
            @extend %neutral-800;
            @extend %pt-7;
        }
        &-selection {
            border-bottom: .1rem solid var(--pure-white-800);
            gap: 1.6rem;
            padding-block: var(--space-7);
            @extend %flex;
        }
        &-count {
            @extend %product-count;
        }
        &-cart {
            flex: 1;
            @extend %flex;
            button:disabled,
            button[disabled] {
                cursor: not-allowed;
                pointer-events: none;
                border: .1rem solid var(--pure-white-400);
                @extend %neutral-800;
                @extend %pure-white-400-bg;
            }
            a:disabled,
            a[disabled] {
                cursor: not-allowed;
                pointer-events: none;
                border: .1rem solid var(--pure-white-400);
                @extend %neutral-800;
                @extend %pure-white-400-bg;
            }
        }
        &-thumbnail,
        &-details {
            @extend %w-100;
        }
        &-detail-wrapper {
            @extend %pb-15;
            @extend %flex-wrap;
            @extend %flex;
        }
        &-content {
            .disclaimer {
                @extend %mt-2;
            }
            .dropdown-wrap {
                @extend %pt-7;
                .dropdown-label {
                    @extend %flex;
                    @extend %mb-2;
                    @extend %neutral-800;
                    @extend %capitalize;
                    @extend %font-14-pm;
                }
                .select-dropdown {
                    border: .1rem solid var(--neutral-1000);
                    outline: none;
                    height: 4.2rem;
                    cursor: pointer;
                    @extend %neutral-800;
                    @extend %half-radius;
                    @extend %p-2;
                    @extend %w-100;
                    @extend %transparent-bg;
                    &.disabled {
                        pointer-events: none;
                        opacity: .5;
                    }
                }
            }
        }
    }
    .price {
        &-section {
            border-bottom: .1rem solid var(--pure-white-800);
            gap: var(--space-4);
            @extend %flex;
            @extend %p-4-0;
        }
        &-previous {
            @extend %neutral-400;
            @extend %line-through;
            @extend %font-24-pr;
        }
        &-current {
            @extend %neutral-800;
            @extend %font-24-pb;
        }
    }
    .btn {
        @extend %btn-cart;
        &-cart {
            flex: 1;
            height: 4.2rem;
            color: var(--neutral-800);
            font-weight: 700;
            @extend %flex-c-c;
            @extend %p-3-13;
        }
        .btn-text {
            font-size: 1.4rem;
            font-weight: 700;
        }
    }
    .tab {
        &-section {
            padding-block: var(--space-6) var(--space-0);
        }
        &-name {
            flex: unset;
            width: fit-content;
            @extend %relative;
            &.active {
                @extend %pure-white-900-bg;
                .tab-text {
                    @extend %neutral-800;
                }
                &::after {
                    opacity: 1;
                }
            }
            &::after {
                content: "";
                opacity: 0;
                transition: .3s;
                height: .1rem;
                bottom: 0;
                left: 0;
                @extend %w-100;
                @extend %absolute;
                @extend %neutral-800-bg;
            }
        }
        &-anchor {
            background-color: hsl(var(--hsl-neutral-100)/0.2);
            @include flex-config(flex, null, flex-start, null);
        }
        &-text {
            @extend %font-14-pb;
            @extend %neutral-500;
            @extend %capitalize;
        }
        &-description {
            @extend %pt-7;
            @extend %neutral-800;
        }
    }
    .tabs {
        gap: 2rem;
    }
    .text {
        line-height: 2rem;
        @extend %font-14-pr;
        @extend %pb-3;
    }
    ul {
        @extend %pl-8;
        @extend %pb-3;
    }
    .article-thumbnail {
        img {
            max-width: 100%;
            width: max-content;
            max-height: 100%;
            height: auto;
            margin: auto;
            display: block;
            inset: 0;
        }
    }
    .article-item {
        @extend %w-50;
    }
    .btn-disabled {
        opacity: .5;
        pointer-events: none;
    }
    .zoom-preview {
        display: none;
    }
}
@include mq(col-md) {
    .waf-wp-shop-detail {
        .product-detail-wrapper {
            flex-wrap: nowrap;
            gap: 1.6rem;
        }
        .product {
            &-thumbnail {
                width: 40%;
            }
            &-details {
                width: 60%;
            }
            &-content {
                .dropdown-wrap {
                    width: 20%;
                    .select-dropdown {
                        width: max-content;
                        min-width: 14rem;
                    }
                }
            }
        }
        .preview-swiper {
            margin-bottom: var(--space-0);
            img {
                cursor: zoom-in;
            }
        }
        .thumbnail-swiper {
            display: block;
            padding: var(--space-4) var(--space-0);
            .article-item {
                cursor: pointer;
                background-color: hsl(var(--hsl-neutral-100)/0.2);
                padding: var(--space-3) var(--space-12);
                border-radius: var(--half-radius);
            }
        }
        .title {
            font-size: 3.6rem;
            margin-top: var(--space-2-neg);
        }
        .text,
        p {
            padding-bottom: var(--space-3);
        }
        .btn-cart {
            flex: unset;
            padding: var(--space-3) var(--space-20);
            color: var(--neutral-800);
        }
        .waf-body {
            position: relative;
        }
        .zoom-preview {
            display: block;
            position: absolute;
            inset: 0;
            pointer-events: none;
            height: calc(var(--window-inner-height) - var(--header-height) - 6rem);
            background-repeat: no-repeat;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: auto;
                bottom: auto
            }
            &.active {
                background-color: var(--neutral-50)
            }
        }
    }
}
@include mq(col-lg) {
    .waf-wp-shop-detail {
        .btn {
            .btn-text {
                font-size: 1.4rem;
            }
        }
    }
}