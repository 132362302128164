@use "../config/" as *;
@forward "../shop/shop-common";
.waf-wp-shop-listing {
    padding: var(--space-0) var(--space-4);
    .filter-section,
    .pagination-wrap,
    .result-wrap,
    .waf-select-box,
    .loadmore-wrap {
        @extend %d-none;
    }
    .waf-head {
        @include drop-arrow();
        --_border-color: var(--secondary-800);
        --_more-color: var(--secondary-800);
        .head-wrap {
            margin-bottom: var(--space-5);
            @extend %flex-nowrap;
            .title {
                width: unset;
            }
        }
    }
    .waf-body {
        @extend %pb-2;
        .article-list {
            overflow-x: auto;
            @extend %flex;
            @extend %gap-4;
            @extend %pb-5;
            @extend %flex-nowrap;
        }
        .article-wrap {
            @extend %h-100;
            @extend %flex-column;
        }
        .article-footer {
            @extend %mt-auto;
        }
    }
    .article-item {
        flex: 0 0 auto;
        background-color: hsl(var(--hsl-neutral-100)/0.2);
        .dropdown-wrap {
            @extend %my-2;
            .select-dropdown {
                border: .1rem solid var(--neutral-1000);
                outline: none;
                height: 3.5rem;
                cursor: pointer;
                @extend %neutral-800;
                @extend %half-radius;
                @extend %p-2;
                @extend %w-100;
                @extend %transparent-bg;
                &.disabled {
                    pointer-events: none;
                    opacity: .5;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-wp-shop-listing {
        .layout-wrapper {
            grid-template-columns: auto;
        }
        .waf-body {
            padding-bottom: var(--space-15);
        }
        .article-item {
            width: calc((100% - var(--space-4)) / 4.5);
        }
    }
}